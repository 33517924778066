<template>
  <div class="notice-containter">
    <nav-menu></nav-menu>
    <div class="notice-body">
      <div class="containter flex">
        <div class="notice-left">
          <div>通知公告</div>
        </div>
        <div class="notice-right">
          <div class="notice-list">
            <div class="notice-total flex-a">
              <span class="line"></span>
              <span>共{{total}}条记录</span>
            </div>
            <div class="notice-item" v-for="item in list" :key="item.id" @click="toDetail(item.id)">
              <div class="notice-item-title">{{item.name}}</div>
              <div class="notice-item-content flex-a flex-b">
                <div>{{item.remark}}</div>
                <div class="notice-item-time">{{item.updateTime}}</div>
              </div>
            </div>
          </div>
          <div class="page-list">
            <el-pagination background layout="prev, pager, next" @current-change="handleCurrentChange" :total="total">
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
    <foot></foot>
  </div>
</template>

<script>
import { getNotice, getNoticeByToken } from './../lib/api/index'
export default {
  name: 'notice',
  data() {
    return {
      list: [],
      total: 0
    }
  },
  created() {
    this.getNoticeList({ pageNum: 1, pageSize: 10 })
  },
  mounted() {
  },
  destroyed() {
  },
  methods: {
    getNoticeList(params) {
      if (window.localStorage.getItem("token")) {
        getNoticeByToken(params).then(res => {
          if (res.code == 200) {
            this.list = res.rows
            this.total = res.total
          }
        })
      } else {
        getNotice(params).then(res => {
          if (res.code == 200) {
            this.list = res.rows
            this.total = res.total
          }
        })
      }
    },
    //跳转新闻详情
    toDetail(id) {
      this.$router.push({
        path: '/detail',
        query: { id: id }
      })
    },
    //当前页
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.getNoticeList({ pageNum: val, pageSize: 10 })
    },
  },
  computed: {

  }
}
</script>

<style lang="less">
.notice-containter {
  background: #F0F2F5;
  min-height: 100vh;

  .notice-body {
    padding: 20px 0;

    .containter {
      padding: 0;

      .notice-left {
        background: #F5F6F8;
        width: 200px;
        height: 500px;
        border-radius: 5px;

        div {
          background: #5478C5;
          text-align: center;
          height: 60px;
          line-height: 60px;
          color: #fff;
        }
      }

      .notice-right {
        padding: 20px;
        width: 100%;

        .notice-list {
          border: 1px solid #E1E7EF;
          border-top: none;

          .notice-total {
            background: #F5F6F8;
            height: 40px;
            color: #666;
            font-size: 14px;

            .line {
              display: inline-block;
              width: 3px;
              height: 40px;
              background: #3577E7;
              margin-right: 5px;
            }
          }

          .notice-item {
            height: 70px;
            padding: 15px 15px;
            box-sizing: border-box;

            .notice-item-title {
              font-size: 14px;
              color: #333;
              margin-bottom: 5px;
            }

            .notice-item-content {
              color: #666;
              font-size: 12px;

              div:first-child {
                width: 85%;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                word-break: break-all;
              }

              .notice-item-time {
                color: #999;
              }
            }
          }

          .notice-item:not(:last-child) {
            border-bottom: 1px solid #F3F6F9;
          }

          .notice-item:hover {
            cursor: pointer;
          }
        }

        .page-list {
          margin: 20px auto;
          text-align: center;
        }
      }
    }
  }
}
</style>
